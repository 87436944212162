import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Container } from "../../styles/globalStyles";
import { useInView } from "react-intersection-observer";
import { device } from "../../util/device";

const StyledHomeWhyUs = styled.div`
    padding: 100px 150px;

    @media ${device.small} {
        padding: 20px 10px;
    }
`;

const Grid = styled(motion.div)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
`;

const GridItem = styled(motion.div)`
    padding: 40px 30px;
    margin: 15px;
    border: 1px solid black;
    @media ${device.small} {
        padding: 20px;
        margin: 5px;
        p {
            font-size: 1.4rem;
        }
    }
    h3 {
        margin-bottom: 10px;
        color: #7fceb9;
    }
`;

const HomeWhyUs = () => {
    const { ref, inView, entry } = useInView({
        rootMargin: "-300px",
    });
    const containerVariants = {
        hidden: {},
        show: {
            transition: {
                staggerChildren: 0.1,
                delayChildren: 0.2,
            },
        },
    };
    const childVariants = {
        hidden: { x: -200, scale: 1.1, opacity: 0 },
        show: {
            x: 0,
            scale: 1,
            opacity: 1,
            transition: { duration: 0.6, ease: "easeOut" },
        },
    };
    const data = [
        {
            title: "Lorem Ipsum",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            title: "Lorem Ipsum",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            title: "Lorem Ipsum",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            title: "Lorem Ipsum",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            title: "Lorem Ipsum",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
            title: "Lorem Ipsum",
            content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
    ];
    return (
        <StyledHomeWhyUs ref={ref} id="why-us">
            <Grid
                initial="hidden"
                animate={inView && "show"}
                variants={containerVariants}
            >
                {data.map(({ title, content }) => (
                    <GridItem variants={childVariants}>
                        <h3>{title}</h3>
                        <p>{content}</p>
                    </GridItem>
                ))}
            </Grid>
        </StyledHomeWhyUs>
    );
};

export default HomeWhyUs;
