import React from "react";
import styled from "styled-components";
import { Container } from "../../styles/globalStyles";

import introBg from "../../assets/images/intro-bg.jpeg";
import {
    motion,
    useViewportScroll,
    useTransform,
    useMotionValue,
} from "framer-motion";
import { device } from "../../util/device";

const StyledHomeIntro = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: #202431;
    overflow: hidden;
`;

const GlassDrop = styled.div`
    overflow: hidden;
    /* padding-top: 95px; */
    padding-top: 125px;
    padding-bottom: 80px;
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(25rem);
    display: flex;
    align-items: center;
    background: linear-gradient(
        to bottom right,
        rgba(32, 36, 49, 0.6),
        rgba(32, 36, 49, 0.2)
    );
    /* background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.2)
    ); */
    z-index: 3;
`;

const Bubbles = styled.div`
    position: absolute;

    height: 500px;
    width: 500px;
    border-radius: 250px;
    z-index: 2;
    &.bubble1 {
        top: -10%;
        left: -12%;
        background-color: #f34d71;
    }
    &.bubble2 {
        top: 13%;
        right: 20%;
        background-color: #3eb8ff;
    }
`;

const IntroHero = styled(motion.div)`
    position: relative;
    background: url(${introBg}) center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    /* overflow: hidden; */
    z-index: 4;
    box-shadow: inset 0px 12px 8px -10px rgba(0, 0, 0, 0.5),
        inset 0px -12px 8px -10px rgba(0, 0, 0, 0.5);
`;

const IntroHeroBackdrop = styled(Container)`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding: 0 100px; */
    background: linear-gradient(
        to bottom right,
        rgba(32, 36, 49, 0.4),
        rgba(32, 36, 49, 0.4)
    );
    @media ${device.small} {
        padding: 0 40px;
    }
    p {
        font-family: "Poppins";
        font-weight: 700;
        color: #fff;
        &.header {
            font-size: 2.4rem;
            opacity: 0.75;
            margin-left: 5px;
            @media ${device.small} {
                font-size: 1.8rem;
            }
        }
        &.title {
            font-size: 8rem;
            line-height: 90px;
            letter-spacing: -2px;
            margin: 30px 0;
            @media ${device.small} {
                font-size: 3rem;
                line-height: 50px;
            }
        }
    }
    a {
        display: block;
        position: relative;
        border: none;
        width: 180px;
        height: 60px;
        /* background-color: ${(props) => props.theme.textDark}; */
        background-color: #272727;
        @media ${device.small} {
            width: 120px;
            height: 70px;
        }
        p {
            position: absolute;
            font-family: "Poppins";
            font-weight: 700;
            font-size: 2rem;
            color: #fff;
            bottom: 0;
            right: 10px;
        }
    }
`;

const HomeIntro = () => {
    const { scrollY } = useViewportScroll();
    const y1 = useTransform(scrollY, [0, 1000], [0, -200]);
    const y2 = useTransform(scrollY, [0, 1000], [0, 900]);

    const heroContainerVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3,
                delayChildren: 1,
            },
        },
    };
    const heroTextVariants = {
        hidden: { opacity: 0, x: -100, scale: 1.1 },
        show: (custom) => ({
            opacity: custom,
            x: 0,
            scale: 1,
            transition: { duration: 0.6, ease: "easeOut" },
        }),
    };
    return (
        <StyledHomeIntro id="hero">
            <Bubbles className="bubble1"></Bubbles>
            <Bubbles className="bubble2"></Bubbles>
            <GlassDrop>
                {/* <Container> */}
                <IntroHero
                    // style={{ y: y1 }}
                    style={{ y: y1 }}
                    initial={{
                        height: 0,
                        scale: 2,
                        // backgroundPosition: "0px -900px",
                    }}
                    animate={{
                        height: "100%",
                        scale: 1,
                        // backgroundPosition: "0px 0px",
                        transition: {
                            duration: 2,
                            delay: 0.2,
                            ease: "easeOut",
                        },
                    }}
                >
                    <IntroHeroBackdrop>
                        <motion.div
                            style={{ y: y2 }}
                            initial="hidden"
                            animate="show"
                            variants={heroContainerVariants}
                        >
                            <motion.p
                                variants={heroTextVariants}
                                className="header"
                                custom={0.75}
                            >
                                20+ YRS EXPERIENCE
                            </motion.p>
                            <motion.p
                                variants={heroTextVariants}
                                className="title"
                                custom={1}
                            >
                                Northern Virginia
                                <br />
                                Flooring Services
                            </motion.p>
                            <motion.a
                                variants={heroTextVariants}
                                custom={1}
                                href="#about"
                            >
                                <p>About Us</p>
                            </motion.a>
                        </motion.div>
                    </IntroHeroBackdrop>
                </IntroHero>
                {/* </Container> */}
            </GlassDrop>
        </StyledHomeIntro>
    );
};

export default HomeIntro;
