import React from "react";
import styled from "styled-components";
import { Container } from "../../styles/globalStyles";

// import { Parallax, ParallaxLayer } from "@react-spring/parallax";

const StyledHomeContact = styled.div`
    height: 100%;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    display: grid;
    place-items: center;
    padding: 125px 0;
`;

const Bubbles = styled.div`
    position: absolute;
    height: 500px;
    width: 500px;
    border-radius: 250px;
    z-index: 2;
    &.bubble1 {
        top: -10%;
        left: -12%;
        background-color: #f34d71;
    }
    &.bubble2 {
        top: 13%;
        right: 20%;
        background-color: #3eb8ff;
    }
`;

const GlassDrop = styled.div`
    /* padding-top: 95px; */
    /* display: grid;
    place-items: center; */
    /* padding: 125px 0; */
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(25rem);
    background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.6),
        rgba(255, 255, 255, 0.2)
    );
    z-index: 3;
`;
const ContactForm = styled.form`
    height: 100%;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 4;
`;

const ContactHeader = styled.h2`
    text-align: center;
    color: ${(props) => props.theme.textDark};
    margin-bottom: 30px;
`;

const ContactSubheader = styled.p`
    text-align: center;
    font-size: 2.2rem;
    color: ${(props) => props.theme.textDark};
    margin-bottom: 100px;
`;

const ContactInput = styled.input`
    padding: 15px;
    font-family: "Questrial";
    font-size: 1.7rem;
    border: none;
    border-radius: 4px;
    margin: 10px 0;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.7);
    /* width: 100%; */
    ::placeholder {
        /* color: ${(props) => props.theme.textDark}; */
    }
`;

const ContactTextarea = styled.textarea`
    background: rgba(255, 255, 255, 0.7);

    resize: none;
    padding: 15px;
    font-family: "Questrial";
    font-size: 1.7rem;
    border: none;
    border-radius: 4px;
    margin: 10px 0;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    /* width: 100%; */
    ::placeholder {
        /* color: ${(props) => props.theme.textDark}; */
    }
`;

const ContactButton = styled.button`
    align-self: center;
    padding: 18px;
    border: none;
    background-color: ${(props) => props.theme.textDark};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    font-size: 1.8rem;
    color: #fff;
    font-family: "Questrial";
    font-weight: 700;
    margin: 30px;
    cursor: pointer;
`;

const HomeContact = () => {
    return (
        <StyledHomeContact id="contact">
            <Bubbles className="bubble1"></Bubbles>
            <Bubbles className="bubble2"></Bubbles>
            <GlassDrop></GlassDrop>
            <ContactForm>
                <ContactHeader>Contact Us</ContactHeader>
                <ContactSubheader>
                    We at Mako are here to help with your flooring needs.
                </ContactSubheader>
                <ContactInput type="text" placeholder="Name"></ContactInput>
                <ContactInput type="text" placeholder="Email"></ContactInput>
                <ContactInput
                    type="text"
                    placeholder="Phone Number"
                ></ContactInput>
                <ContactTextarea placeholder="Message" rows="8" />
                <ContactButton
                    type="submit"
                    // onSubmit={(e) => e.preventDefault()}
                    onClick={(e) => e.preventDefault()}
                >
                    Submit
                </ContactButton>
            </ContactForm>
        </StyledHomeContact>
    );
};

export default HomeContact;
