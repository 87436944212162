import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { Container } from "../../styles/globalStyles";
import { useInView } from "react-intersection-observer";
import { device } from "../../util/device";

const StyledHomeAbout = styled.div`
    /* background-color: #283341; */
    /* background-color: #202431; */
    background-color: #fff;
    width: 100%;
    /* min-height: 100vh; */
    /* padding-top: 200px;
    padding-bottom: 100px; */
    padding: 200px 150px 100px 150px;
    height: 100%;
    @media ${device.small} {
        padding: 80px 10px;
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    ${(props) => props.column && `flex-direction: column;`};
    align-items: center;
    /* justify-content: center; */
`;

const Header = styled(motion.h3)`
    font-family: "Questrial";
    font-weight: 600;
    /* color: #fff; */
    color: ${(props) => props.theme.textDark};
    font-size: 4rem;
    margin-bottom: 30px;
`;
const TextContent = styled(motion.p)`
    font-family: "Poppins";
    text-align: center;
    /* color: #fff; */
    color: ${(props) => props.theme.textDark};
    font-size: 1.6rem;
    line-height: 35px;
`;

const VideoWrapper = styled(motion.div)`
    margin-top: 50px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    width: 100%;
`;

const InformationComponent = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    /* background-color: #83efd3; */
    background-color: #a7ffe8;
    margin-right: 50px;

    padding: 30px 60px;
    color: ${(props) => props.theme.textDark};
    border-radius: 30px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    /* justify-content: center; */
    @media ${device.small} {
        margin: 5px;
        padding: 10px 20px;
    }
    .info-header {
        font-size: 2.2rem;
        font-weight: 600;
    }
    .info-content {
        font-size: 1.8rem;
    }
`;

const HomeAbout = () => {
    const { ref, inView, entry } = useInView({
        rootMargin: "-300px",
    });

    return (
        <StyledHomeAbout ref={ref} id="about">
            <ContentWrapper column>
                <Header
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                        inView && {
                            opacity: 1,
                            x: 0,
                            transition: { duration: 1 },
                        }
                    }
                >
                    Mako Flooring Services
                </Header>
                <TextContent
                    initial={{ opacity: 0, x: -100 }}
                    animate={
                        inView && {
                            opacity: 1,
                            x: 0,
                            transition: { duration: 1, delay: 0.3 },
                        }
                    }
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                    {/* We are a family-owned flooring
                        contracting business. Being experienced for over 30+
                        years and holding certififcations of all 3 Contractors
                        Licenses(Class A, B, C), we hold an ultra high standard
                        when it comes to your flooring needs. We can promise
                        you, you will find our pricing the most fair in the
                        business. We also don't attach surprise hidden fees,
                        unlike many other contractors in the business. We're all
                        about honesty and hardwork. */}
                </TextContent>
                <VideoWrapper>
                    <InformationComponent>
                        <p className="info-header">Did you know?</p>
                        <p className="info-content">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                        </p>
                    </InformationComponent>
                    <iframe
                        width="100%"
                        height="315"
                        as="style"
                        // src="https://www.youtube.com/embed/xbOkUWJeVfI"
                        src="https://youtube.com/embed/GSfKiAWVUvM"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </VideoWrapper>
            </ContentWrapper>
        </StyledHomeAbout>
    );
};

export default HomeAbout;
