import React from "react";
import styled from "styled-components";
import HomeAbout from "../components/homePage/HomeAbout";
import HomeContact from "../components/homePage/HomeContact";
import HomeContentSpace from "../components/homePage/HomeContentSpace";
import HomeIntro from "../components/homePage/HomeIntro";
import HomeWhyUs from "../components/homePage/HomeWhyUs";

const StyledHome = styled.div`
    /* height: 100vh;
    width: 100%;
    background-color: red; */
    display: flex;
    flex-direction: column;
`;

const Home = () => {
    return (
        <StyledHome>
            <HomeIntro />
            <HomeAbout />
            <HomeContentSpace />
            <HomeWhyUs />
            <HomeContact />
        </StyledHome>
    );
};

export default Home;
