import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import contentBg from "../../assets/images/content-bg.jpeg";
import { Container } from "../../styles/globalStyles";

import { useInView } from "react-intersection-observer";
import { device } from "../../util/device";

const StyledHomeContentSpace = styled(motion.div)`
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HomeContentWrapper = styled(motion.div)`
    background: url(${contentBg}) center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
    p {
        font-family: "Poppins";
        font-weight: 700;
        color: #fff;
        @media ${device.small} {
            margin: 0;
        }

        &.header {
            font-size: 2.4rem;
            opacity: 0.75;
            margin-left: 5px;
        }
        &.title {
            font-size: 8rem;
            line-height: 80px;
            letter-spacing: -2px;
            margin: 40px 0;
            @media ${device.small} {
                font-size: 4rem;
                line-height: 40px;
            }
        }
    }
    button {
        cursor: pointer;
        position: relative;
        border: none;
        width: 180px;
        height: 60px;
        padding: 0 10px;
        /* background-color: ${(props) => props.theme.textDark}; */
        background-color: #272727;
        p {
            position: absolute;
            font-family: "Poppins";
            font-weight: 700;
            font-size: 2rem;
            color: #fff;
            bottom: 0;
            right: 10px;
        }
    }
`;

const BackDrop = styled.div`
    padding: 0 150px;
    height: 100%;
    @media ${device.small} {
        padding: 20px;
    }
    background: linear-gradient(
        to bottom right,
        rgba(32, 36, 49, 0.3),
        rgba(32, 36, 49, 0.3)
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const HomeContentSpace = () => {
    const { ref, inView } = useInView({
        rootMargin: "-100px 0px -100px 0px",
    });

    const contentContainerVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.4,
                delayChildren: 0.75,
            },
        },
    };
    const contentTextVariants = {
        hidden: { opacity: 0, scale: 0.99 },
        show: (custom) => ({
            opacity: custom,
            scale: 1,
            transition: { duration: 0.6 },
        }),
    };
    return (
        <StyledHomeContentSpace id="product">
            <HomeContentWrapper
                ref={ref}
                initial={{ height: 0 }}
                animate={
                    inView && {
                        height: "100%",
                        transition: {
                            duration: 1.6,
                            delay: 0.2,
                            ease: "easeOut",
                        },
                    }
                }
            >
                <BackDrop>
                    <motion.div
                        initial="hidden"
                        animate={inView && "show"}
                        variants={contentContainerVariants}
                    >
                        <motion.p
                            variants={contentTextVariants}
                            className="header"
                            custom={0.75}
                        >
                            RELIABLE, TRUSTWORTHY, PERFECTION
                        </motion.p>
                        <motion.p
                            variants={contentTextVariants}
                            className="title"
                            custom={1}
                        >
                            Family-Owned
                            <br />
                            Flooring Business
                        </motion.p>
                        <motion.button
                            variants={contentTextVariants}
                            custom={1}
                        >
                            <p>FREE ESTIMATE</p>
                        </motion.button>
                    </motion.div>
                </BackDrop>
            </HomeContentWrapper>
        </StyledHomeContentSpace>
    );
};

export default HomeContentSpace;
